import React from 'react'
import '../styles/largeCarousel.css'
import { Carousel } from 'react-responsive-carousel'
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css'
import mountainView from '../images/mountainView.jpg'
import peopleCrop from '../images/peopleCrop.jpg'
import library from '../images/library.jpg'
import { Link } from 'react-router-dom'

export const LargeCarousel = () => {
  return(
    <Carousel showArrows={false}
      showStatus={false}
      showIndicators={true}
      showThumbs={false}
      infiniteLoop={true}
      width={'100%'}
      autoPlay={true}
      interval={5000}
      transitionTime={400}
      emulateTouch={true}
      stopOnHover={false}>

      <div className='carouselDiv'>
        <img src={mountainView} alt='mountain' loading="lazy"/>
        <Link to='/boundaries'><h3 className="legends">Boundaries</h3></Link>
      </div>
      <div  className='carouselDiv'>
        <img src={peopleCrop} alt='people' loading="lazy"/>
        <Link to='/people'><h3 className="legends">People</h3></Link>
      </div>
      <div className='carouselDiv'>
        <img src={library} alt='library' loading="lazy"/>
        <Link to='/history'><h3 className="legends">History</h3></Link>
      </div>
    </Carousel>
  )
}
