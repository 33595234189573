import React, { useEffect } from 'react';
import '../styles/history.css';

const History = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className='history-page container'>
      <div className='historyCard col-md-12'>
        <div className='col-md-12'>
          <div className="titleContainer">
            <div className='historyTitle'>History</div>
            <div className='historyTitle'>by</div>
            <div className='historyTitle'>Arthur A Ellison</div>
          </div>
          <p>
            In February of 2000 Danny Matthews (now Pastor of Prayer at Two Rivers Church, Knoxville), my wife Karen, and I attended a conference for prolife, pregnancy center leaders. We heard a powerful teaching from Joshua 5 on living and leading from the presence of God.  Early the next morning at 3:00am the Lord abruptly woke me up and clearly said – almost as if it was His audible voice -, “Call the leaders of the prolife, pregnancy center movement to gather together in Knoxville to meet with Me to be in My presence – just to be with Me.”
          </p>
          <p>
            I deeply wrestled with that experience for six months.  Was that really God?  Why me? Would any national leadership even come with such a seemingly undefined purpose?  Finally, after a supernatural confirmation that I had heard His “still small voice”, I obeyed.  Danny and I worked closely together to design a simple structure for the meeting and recruited the teams – a worship team, an on-site intercessor team providing prayer support for the event, a facilitator and then the participants. A list of twenty-five national leaders from the US was developed, and candidly, with only a small seed of faith on my part, they were personally invited.
          </p>
          <p>
            Eighteen said yes. I was both shocked and now scared.  What had we just done!  What were we going to do with these national leaders for three days???
          </p>
          <p>
            On August 19, 2000 the eighteen showed up.  Our facilitator, Dr. Keith Yoder did an amazing job as did the worship team led by Pastor Jim Price from Heritage Fellowship in Jefferson City, TN and their gifted worship pastor, Stephen Jones.
          </p>
          <p>
            Most importantly, God showed up. Powerfully.  Personally.
          </p>
          <p>
            During the meeting I slipped out of the meeting and made a brief visit to the intercessor’s meeting next door. One of them, Mark Pate, felt prompted by the Holy Spirit to ask me to read the Joshua 5 passage out loud.  I did and what happed next changed the trajectory of my life. The Holy Spirit, very unexpectedly and powerfully, descended into the room and all of us found ourselves flat on the floor weeping.  He then clearly spoke to me again and gave me a life assignment - call His leaders to lead from abiding in His presence which is a core truth in the Joshua 5:13-15 passage.
          </p>
          <p>
            2nd Question was birthed.
          </p>
          <p>
            A 2nd event was held three months later in November. Word had spread.  Fifty plus leaders came for a 2nd very powerful meeting with our heavenly Father.  Leaders from several national organizations publicly repented for their competition and inappropriate self-focus.  A new unity was birthed in the prolife pregnancy center movement.
          </p>
          <p>
            Several more of these events were held among prolife pregnancy center groups over the next couple of years. In 2002, we created a new ministry organization to continue conducting these events and calling leaders to this biblical paradigm of leadership.  The name we gave it, 2nd Question, comes from the 2nd question asked by Joshua in the text of Joshua 5:14, “What does my Lord have to say to His servant?”  Our mission came from the new life assignment given to me during our 1st event in August 2000.
          </p>
          <p>
            Later that year Danny and I approached Brad Brinson, the Founding & Senior Pastor of Two Rivers Church, Knoxville and asked if our 2nd Question team could conduct one of these events for the church.  He said yes . . . and we did.  The result was again, powerful, and Two Rivers has been conducting these events ever since then, now twice a year. The name of these meetings, Quiet Waters, was suggested by Bart Pederson, one of our original Two Rivers pastors - and the name stuck to this day.
          </p>
          <p>
            Since that first Quiet Waters in 2000, these events have now been held by trained teams across the US, Asia, Africa, Europe, Latin America and the Middle East using a variety of names and in so many different types of venues. But the results have always been the same . . . a powerful yet personal encounter with our amazing Father.
          </p>
        </div>
      </div>
    </div>
  )
};

export default History;