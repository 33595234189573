import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/about.css';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='about container'>
      <h3 className='aboutTitle'>About</h3>
      <div className='row'>
        <div className='col-sm-12 col-md-4 imageColumn'>
          <Link to='/boundaries'><div className='mountainView'></div></Link>
          <p className='summaryText'>Keeping us Protected, Focused, & Fruitful</p>
          <Link to='/boundaries' className='aboutLink'>Boundaries</Link>
        </div>
        <div className='col-sm-12 col-md-4 imageColumn'>
          <Link to='/people'><div className='people'></div></Link>
          <p className='summaryText'>Checkout our Board of Directors</p>
          <Link to='/people' className='aboutLink'>People</Link>
        </div>
        <div className='col-sm-12 col-md-4 imageColumn'>
          <Link to='/history'><div className='library'></div></Link>
          <p className='summaryText'>How we got to the Second Question</p>
          <Link to='/history' className='aboutLink'>History</Link>
        </div>
      </div>
    </div>
  )
};

export default About;
