import React, { useEffect } from 'react';
import arthur from '../images/arthur.jpg';
import '../styles/contact.css';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openEmail = (email) => {
    document.location.href = `mailto:${email}?Contact&body=message%20goes%20here`
  };

  return (
    <div className='contact-page container'>
      <h1 className='contactTitle'>Contact</h1>
      <div className='row'>
        <div className='col-md-3'>
        </div>
        <div className='col-md-6'>
          <img className='contactImage col-md-12' src={arthur} alt='arthur' loading="lazy" />
          <div className='contactText col-md-12'>
            <div>Arthur A Ellison</div>
            <div>President</div>
            <div>Mailing Address: 1460 Kenesaw Avenue</div>
            <div>Knoxville, TN 37919</div>
            <div>Office: (865) 524-3313</div>
            <div>Mobile: (865) 603-8642</div>
            <div>Email: <span className='emailLink' onClick={() => openEmail('arthur@2q.org')}>Arthur@2Q.org</span></div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Contact;