import React, { useEffect } from 'react';
import '../styles/boundaries.css';

const Boundaries = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='boundaries-page container'>
      <h3 className='boundariesTitle'>Boundaries: Keeping us Protected, Focused, & Fruitful</h3>
      <div className='boundariesCard col-md-12'>
        <h4 className='subTitle'>Mission</h4>
        <hr />
        <p className='text'>
          The mission of 2nd Question is to multiply Christian leaders whose influence comes from abiding in Christ, listening to His voice, and strategic thinking.
        </p>
      </div>

      <div className='boundariesCard col-md-12'>
        <h4 className='subTitle'>Guiding Principles</h4>
        <hr />
        <h5 className='textTitle'>Worship</h5>
        <p className='text'>
          True worship, and all which brings glory to our God, is the highest priority and deepest passion of our work and ministry activities, both corporately and individually.
        </p>
        <br />
        <h5 className='textTitle'>Authority</h5>
        <p className='text'>
          Determined to be persons of great faith, spiritual authority, and spiritual power, we honor the authorities in the local settings where we both live and are sent to serve--staying in submission to them with the essential character quality of humility.
        </p>
        <br />
        <h5 className='textTitle'>Health</h5>
        <p className='text'>
          We are committed to being healthy workers, living and ministering in healthy teams and equipping healthy leaders.
        </p>
        <br />
        <h5 className='textTitle'>Prayer & Fasting</h5>
        <p className='text'>
          Our foundation of prayer and fasting both precedes and exceeds the breadth of our ministry activities.
        </p>
        <br />
        <h5 className='textTitle'>Direction</h5>
        <p className='text'>
          As we live together in His presence, God's sovereign vision, strategy, tactics, direction, and motivation will come from the Scriptures, the prophetic, and the strategic leadership He is developing in us.
        </p>
        <br />
        <h5 className='textTitle'>Equipping</h5>
        <p className='text'>
          Equipping leaders through modeling the process of being in His presence is possible only as we embrace a lifestyle of being in His presence ourselves.
        </p>
        <br />
        <h5 className='textTitle'>Inclusiveness</h5>
        <p className='text'>
          While uncompromisingly holding to core Christianity and the authority of the Scriptures, we demonstrate the cultural sensitivity and adaptation required to minister in the diverse global body of Christ.
        </p>
      </div>
      <div className='boundariesCard col-md-12'>
        <h4 className='subTitle'>Statement of Faith</h4>
        <hr />
        <p className='text'>
          1.      We believe the Scriptures, both Old and New Testaments, to be the inspired Word of God, without error in the original writings, and the revelation of His will for the salvation of men, and divine authority for all Christian faith and life.
        </p>
        <p className='text'>
          2.      We believe in one God, Creator of all things, eternally existing in three persons,  Father, Son and Holy Spirit.
        </p>
        <p className='text'>
          3.      We believe that Jesus Christ was conceived of the Holy Spirit and born of the Virgin Mary. And we believe in His sinless life, in His miracles and that He died on the cross as a sacrifice for our sins according to the Scriptures. Further, we believe He arose bodily from the dead, ascended into heaven, where, at the right hand of the Father, He now is our high priest and advocate.
        </p>
        <p className='text'>
          4.      We believe that the ministry of the Holy Spirit is to glorify the Lord Jesus Christ and to convict, regenerate, baptize, indwell, fill, anoint, empower, guide, instruct, comfort, and distribute spiritual gifts to the believer for godly living and service.
        </p>
        <p className='text'>
          5.      We believe that God uniquely created man and woman in His own image; He created them as male and female; and that God ordained the Biblical model of marriage to be between one man and one woman.
        </p>
        <p className='text'>
          6.      We believe that mankind fell into sin and is, therefore, lost and separated from God; and that the Lord Jesus Christ died for our sins as a representative and substitutionary sacrifice; and that all who believe in Him are justified by His blood which He shed on the cross.
        </p>
        <p className='text'>
          7.      We believe that the true Church is composed of all such persons who through saving faith in Jesus Christ have been regenerated by the Holy Spirit and are united together in the body of Christ, of which He is the head.
        </p>
        <p className='text'>
          8.      We believe in the personal coming again of our Lord Jesus Christ, and the bodily resurrection of the unjust and the just. The unjust shall be eternally separated from God's presence, but the just shall live and reign with Him forever.
        </p>
        <p className='text'>
          9.      We believe the Lord Jesus Christ commanded all believers to proclaim the gospel throughout the world and to make disciples of all the nations. He also commanded us to be salt and light, demonstrating both justice and also compassion for the hurts and social needs of our fellowmen.
        </p>
      </div>
    </div>
  )
};

export default Boundaries;