import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import facebookIcon from '../images/facebookIcon.png'
import instagramIcon from '../images/instagramIcon.png'
import logoFooter from '../images/logoFooter.png'
import twitterIcon from '../images/twitterIcon.png'
import '../styles/footer.css'

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className='footer-main'>
        <div className='row'>
          <div className='col-md-3 offset-md-1'>
            <div onClick={() => navigate('/')} className='footerLink'>
              <img className='logoFooter' src={logoFooter} alt='logo'/>
            </div>
            <a href="https://www.facebook.com/2ndQuestion" target="_blank" rel="noopener noreferrer">
              <img className='facebookIcon' src={facebookIcon} alt='facebookIcon'/>
            </a>
            <a href="https://twitter.com/2nd_Question" target="_blank" rel="noopener noreferrer">
              <img className='twitterIcon' src={twitterIcon} alt='twitterIcon'/>
            </a>
            <a href="https://www.instagram.com/2ndquestion" target="_blank" rel="noopener noreferrer">
              <img className='instagramIcon' src={instagramIcon} alt='instagramIcon'/>
            </a>
          </div>

          <div className='col-md-4 offset-md-3 footerText'>
            <div><Link to='/contact' className='footerLink'>Contact</Link></div>
            <div>Arthur A Ellison, President</div>
            <div>1460 Kenesaw Avenue</div>
            <div>Knoxville, TN 37919</div>
            <div>Phone: (865) 603-8642</div>
          </div>
        </div>
      </div>
      <div className='copyright'>Copyright 2017 2nd Question</div>
    </div>
  )
};

export default Footer;
