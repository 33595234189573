import React, { useEffect } from 'react'
import background from '../images/background.png'
import '../styles/home.css'
import { LargeCarousel } from './LargeCarousel'
import { useLocation } from 'react-router-dom'

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const renderText = () => {
    return (
      <div className='homeText'>
        And Joshua asked his 2nd question, &nbsp;
        <b>“What has my Lord to say to His servant?”</b> &nbsp; Joshua 5:13-15
      </div>
    )
  }

  return(
    <div>
      <div className='backgroundImage'>
        <img className='bigLogo' src={background} alt='bigLogo' loading="lazy"></img>
      </div>
      {renderText()}
      <LargeCarousel/>
    </div>
  )
}
export default Home;
