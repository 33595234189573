import React from 'react'
import '../styles/person.css'

export const Person = ({ image, alt, name, content }) => {
  return (
    <div className='row staffCard'>
      <div className='col-md-12 col-lg-6'>
        <img className='staffImage' src={image} alt={alt} loading="lazy"></img>
      </div>
      <div className='col-md-12 col-lg-6 staffText'>
        <h4 className='staffText'>{name}</h4>
        <p>{content}</p>
      </div>
    </div>
  );
}
