import { useEffect } from 'react'
import arthur from '../images/arthur.jpg'
import danny from '../images/danny.jpg'
import john from '../images/john.jpg'
import catherine from '../images/catherine.jpg'
import '../styles/people.css'
import { Person } from './Person'


const arthurContent = (
  <>
    <>
      {"Arthur is the President & Co-Founder of 2nd Question which he co-founded in 2002 with board Chairman Danny Matthews. Arthur, an ordained minister, has an undergraduate degree in Human Services from the University of Tennessee - Knoxville, and a Master of Divinity degree from Trinity Evangelical Divinity School. He has served as a pastoral staff member in two churches. In 1985 he transitioned to become the founding president of a Pro-Life pregnancy center organization which rapidly grew to become one of the five largest in the country. In 1989 he transitioned to become the Founder & President of Alternatives Group (later called LifeSteward Ministries), a consulting & training organization serving Pro-Life pregnancy organizations, which he led for the next 13 years. In 2008 he cofounded with his bride, Karen, the post-abortion healing ministry of Deeper Still "}
    </>
    <a href="https://www.DeeperStill.org" alt="deeper still">{"(www.DeeperStill.org)"}</a>
    <>
      {" which Karen is President of and currently has 32 chapters in the US and also chapters in Asia. For 7 years beginning in 2010 Arthur was secunded from 2nd Question to the senior leadership team of EFCA ReachGlobal (a missionary agency) as their Director of PrayGlobal and ministered very broadly around the globe. Arthur is devoted to strategic leadership and to cultivating spiritual intimacy with God. He lives in his native town of Knoxville, TN with Karen - who he knows is extraordinary!!! They have three grown children, and three grandchildren. He loves international travel & ministry (he has over 3 million miles on Delta), Rubio’s fish tacos, movies, and doing genealogical research on his family."}
    </>
  </>
)

const dannyContent = <>{"Danny's unexpected route to pastoral ministry started in the marketplace as a District Sales Manager for Ecolab, a fortune 500 company, since his formal education was in Marketing & Sales Management. While living in Knoxville, he became part of the founding launch team for a new church, Two Rivers Church. As a layman, Danny served this new church in the areas of worship, prayer and as an elder. And there he also met Arthur Ellison. They quickly became friends which led him to joining Arthur in his national consulting and training firm serving Pro-Life organizations. Working together, they birthed what is now Quiet Waters and the ministry & organization of 2nd Question. Danny later joined the staff of Two Rivers where he now serves as the Pastor of Prayer & Care Ministries and gives oversight to their Quiet Waters retreats, the Well and various other prayer & care ministries for Two Rivers and beyond. Danny is married to his best friend, Lora, and they have three grown children and five grandchildren. Danny enjoys golf, grilling or smoking any kind of meat and just hanging with the family."}</>
const johnContent = <>{"John is a physician and native Californian who relocated to Knoxville in 2018 with his family after a four year sojourn in Pennsylvania. Along the way he has grown more and more passionate about a life lived listening to and following the Holy Spirit, steeped in worship and prayer. He has a father's heart and loves to create safe spaces for those who are moving into their strengths and freedom, as well as leading people into the presence of God through worship. His favorite worship team is his family. He and Catherine have two wonderful kids, Maddy and Sam."}</>
const catherineContent = <>{"Catherine Owens has a passion to help others recognize and respond to the voice of God. An educator in various capacities, she most recently wrapped up a twelve year stint homeschooling her own two children. She is an Active certified trainer with Prophetic Company Global and a certified prophetic processing coach. Catherine loves travelling, building community, and encouraging the body of Christ to step into freedom and wholeness."}</>

const People = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='people-page container'>
      <h3 className='peopleTitle'>Our Board of Directors</h3>
      <Person image={arthur} alt={'arthur'} name="Arthur A Ellison: President & Co-Founder" content={arthurContent} />
      <Person image={danny} alt={'danny'} name="Danny Matthews: Chairman of the Board" content={dannyContent} />
      <Person image={john} alt={'John Owens'} name="John Owens" content={johnContent} />
      <Person image={catherine} alt={'Catherine Owens'} name="Catherine Owens" content={catherineContent} />
    </div>
  )
};

export default People;
