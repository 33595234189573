import { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../images/logo.png'
import '../styles/nav.css'

export const Nav = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e, path) => {
    e.preventDefault();
    setIsOpen(false);
    navigate(path);
  };

  if (window.innerWidth < 1000) {
    return (
      <Menu pageWrapId="page-wrap" width={ '15em' } isOpen={isOpen} onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)}>
        <h5 className='col-sm-12'><a href="/" onClick={(e) => handleClick(e, "/")} className='burgerLink'>Home</a></h5>
        <h5 className='col-sm-12'><a href="/about" onClick={(e) => handleClick(e, "/about")} className='burgerLink'>About</a></h5>
        <h5 className='col-sm-12'><a href="/contact" onClick={(e) => handleClick(e, "/contact")} className='burgerLink'>Contact</a></h5>
      </Menu>
    )
  } else {
    return (
      <div className='container nav-container'>
        <div className='row'>
          <div className='col-sm-12 col-md-2'>
            <Link to='/'><img className='logo' src={logo} alt='logo' loading="lazy"></img></Link>
          </div>
          <h5 className='col-sm-12 offset-sm-0 col-md-1 offset-md-6 navPad'><Link to='/about' className='navLink'>About</Link></h5>
          <h5 className='col-sm-12 col-md-1 navPad'><Link to='/contact' className='navLink'>Contact</Link></h5>
        </div>
        <hr/>
      </div>
    )
  }
};
