import { Route, Routes } from 'react-router-dom';
import './App.css';

import { useEffect } from 'react';
import './App.css';
import About from './components/About.jsx';
import Boundaries from './components/Boundaries.jsx';
import Contact from './components/Contact.jsx';
import Footer from './components/Footer.jsx';
import History from './components/History.jsx';
import Home from './components/Home.jsx';
import { Nav } from './components/Nav.jsx';
import People from './components/People.jsx';

function App() {
  useEffect(() => {
    if (window.location.href.includes('http://www.2q.org/')) {
      window.location.replace('https://www.2q.org');
    }
  }, []);

  return (
    <div className='App'>
      <Nav />
      <Routes>
        <Route path='/' element={Home()} />
        <Route path='/about' element={About()} />
        <Route path='/contact' element={Contact()} />
        <Route path='/boundaries' element={Boundaries()} />
        <Route path='/people' element={People()} />
        <Route path='/history' element={History()} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
